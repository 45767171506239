body {
    margin: 0;
}

.MuiTypography-root {
    font-family: 'Mukta' !important;
    line-height: 1;
}

.publicFooter .MuiTypography-root {
    color: #fff;
    line-height: 1.4,
}